* {
  margin: 0;
  padding: 0;
}

.main {
  background: var(--Shade-Black, #000);
  min-height: 100vh;
  color: white;
  min-width: 100vw;
}

.navbar {
  display: flex;
  padding: 20px 80px;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background: var(--Shade-Black, #000);
  z-index: 9999;
}

.nav_rounded_btn {
  all: unset;
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 100px;
  background: var(--Natural-900, #212121);
  color: var(--Natural-200, #eee);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.hero_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 116px 80px;
  /* margin-top: 60px; */
  background: url("https://anchors-assets.s3.ap-south-1.amazonaws.com/Image+folder/Homepage+background.svg")
    no-repeat;
}

.hero {
  text-align: center;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 44px;
  margin-top: 60px;
}

.hero_title_lg {
  font-family: "Public Sans";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 20px;
  margin-bottom: 12px;
}

.hero_description {
  color: var(--Natural-600, #757575);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: -0.4px;
  max-width: 445px;
}

.section_description {
  color: var(--Natural-600, #757575);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: -0.4px;
}

.title_gradient {
  background: linear-gradient(
    176deg,
    rgba(255, 255, 255, 0.8) 28.93%,
    rgba(153, 153, 153, 0.4) 96.43%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero_footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.hero_button {
  all: unset;
  display: flex;
  padding: 14px 101px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: var(--Natural-300, #e0e0e0);
  color: var(--Natural-900, #212121);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.joined_creator_holder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

/* .creator_profile_holder {
    display: flex;
    align-items: flex-start;
}

.circular_profile {
    width: 30px;
    height: 30px;
    border-radius: 1000px;
    border: 1.5px solid var(--Primary-300, #FF5C5C);
} */

.avatars {
  display: inline-block;
  /* transform: scale(-1, 1); */
}

.avatar {
  position: relative;
  display: inline-block;
  border: 1.5px solid var(--Primary-300, #ff5c5c);
  border-radius: 50%;
  overflow: hidden;
  width: 30px;
}

.avatar:not(:first-child) {
  margin-left: -9px;
}

.avatar img {
  width: 100%;
  display: block;
  /* transform: scale(-1, 1); */
}

.title3 {
  color: var(--Primary-300, #ff5c5c);
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  /* 140% */
  letter-spacing: -0.4px;
  text-transform: uppercase;
}

.section {
  display: flex;
  justify-content: space-between;
  padding: 116px 80px;
  align-items: center;
  max-width: 1367px;
  margin: auto;
  flex-wrap: wrap;
}

.section_part_one {
  width: fit-content;
}

.section_title {
  color: #999;
  font-family: "Public Sans";
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  /* 137.143% */
  text-transform: uppercase;
  margin-top: 12px;
  margin-bottom: 24px;
}

.no_card_req {
  color: #00aa5b;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 233.333% */
  letter-spacing: -0.24px;
  text-transform: uppercase;
  width: 50%;
  text-align: center;
}

.faq_section {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  flex-direction: column;
  margin-top: 40px;
}

.faq_section_item {
  padding: 16px 24px;
  box-sizing: border-box;
  width: 100%;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background: #252525;
}

.faq_section_item_header {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-top: 10px;

  color: var(--Natural-400, #bdbdbd);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  /* 175% */
  letter-spacing: -0.32px;
}

.faq_section_item_header::after {
  font-size: 1.9rem;
  position: absolute;
  right: 1rem;
}

.faq_section_item_body {
  width: 90%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: rgba(255, 255, 255, 0.5);
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  margin-top: 12px;
}

.faq_section_item_body_content {
  text-align: left;
  color: var(--Natural-600, #757575);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
  letter-spacing: -0.28px;
}

.vector_hr {
  width: 543px;
  height: 1px;
  padding: 8px 0;
  background: -webkit-gradient(
    linear,
    0 0,
    100% 0,
    from(rgba(0, 0, 0, 0)),
    color-stop(0.5, #333333),
    to(rgba(0, 0, 0, 0))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0),
    #333333,
    rgba(0, 0, 0, 0)
  );
  background: -moz-linear-gradient(
    left,
    rgba(0, 0, 0, 0),
    #333333,
    rgba(0, 0, 0, 0)
  );
  background: -o-linear-gradient(
    left,
    rgba(0, 0, 0, 0),
    #333333,
    rgba(0, 0, 0, 0)
  );
  background: linear-gradient(
    left,
    rgba(0, 0, 0, 0),
    #333333,
    rgba(0, 0, 0, 0)
  );
  border: 0;
}

.vector_hr:after {
  display: block;
  content: "";
  height: 1px;
  background-image: -webkit-gradient(
    radial,
    50% 0%,
    0,
    50% 0%,
    116,
    color-stop(0%, #cccccc),
    color-stop(100%, rgba(255, 255, 255, 0))
  );
  background-image: -webkit-radial-gradient(
    center top,
    farthest-side,
    #cccccc 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background-image: -moz-radial-gradient(
    center top,
    farthest-side,
    #cccccc 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background-image: -o-radial-gradient(
    center top,
    farthest-side,
    #cccccc 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background-image: radial-gradient(
    farthest-side at center top,
    #cccccc 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.footer_container {
  padding: 75px 80px;
  background-size: cover;
  background-position: center center;
  scroll-snap-align: end;
  background-image: url("../../Components/Editor/New UI/Main Page/home_images/nine.webp");
}

.footer_logo_text {
  color: #fff;
  text-align: center;
  font-family: Sora;
  font-size: 136px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  text-transform: lowercase;
}

.footer_links_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
  gap: 10%;
}

.footer_link_holder {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

.footer_link_holder a {
  all: unset;
  text-align: right;
  font-family: "Gilroy-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  color: white;
}

.footer_link_holder a:hover {
  color: var(--Primary-300, #ff5c5c);
}

.footer_link_title {
  color: var(--Primary-300, #ff5c5c);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  /* 140% */
  letter-spacing: -0.4px;
  text-transform: uppercase;
}

@media (max-width: 600px) {
  .navbar {
    padding: 12px 22px;
  }

  .nav_rounded_btn {
    padding: 5px 20px;
    gap: 10px;
    font-size: 14px;
  }

  .title3 {
    font-size: 14px;
    letter-spacing: -0.28px;
  }

  .hero_title_lg {
    display: block;
    text-align: center;
    font-family: "Public Sans";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .hero {
    min-height: 100svh;
  }

  .hero_container {
    padding: 0px 35px;
    background: url("https://anchors-assets.s3.ap-south-1.amazonaws.com/Image+folder/background+-+Homepage+-+phone.svg")
      no-repeat;
  }

  .hero_description {
    font-size: 14px;
    line-height: 24px;
    /* 171.429% */
    letter-spacing: -0.28px;
  }

  .hero_button {
    padding: 12px 40px;
    width: fit-content;
    font-size: 14px;
  }

  .section {
    padding: 32px 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .section_part_one {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .section_part_two {
    width: 100%;
  }

  .section_part_two > img {
    width: 100%;
    height: 489px;
  }

  .section_part_two .section_part_two_image {
    height: 267px;
  }

  .section_title {
    font-size: 30px;
    line-height: normal;
  }

  .section_description {
    font-size: 14px;
    line-height: 24px;
    /* 171.429% */
    letter-spacing: -0.28px;
  }

  .vector_hr {
    width: 100%;
  }

  .faq_section_item_header {
    font-size: 14px;
  }

  .faq_section_item_body_content {
    font-size: 12px;
    line-height: 20px;
    /* 166.667% */
    letter-spacing: -0.24px;
  }

  .footer_container {
    padding: 22px;
    background-image: url("../../Components/Editor/New UI/Main Page/home_images/tenth.webp");
  }

  .footer_logo_text {
    font-size: 40px;
  }

  .footer_links_container {
    margin-top: 0;
    flex-direction: column;
  }

  .footer_link_holder {
    justify-content: center;
    align-items: center;
  }

  .footer_link_title {
    margin-top: 30px;
  }
}
