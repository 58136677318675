
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inria+Serif:ital,wght@0,300;0,400;1,300&display=swap');



body {
  margin: 0;
  width: 100vw;
  overflow-x: hidden;
  font-family: 'Roboto',-apple-system, BlinkMacSystemFont, 'Segoe UI',  'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  -webkit-tap-highlight-color: transparent;
}

/* Chrome, Safari, Edge, Opera to remove up down arrow from input number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


@font-face {
  font-family: 'Gilroy-Black';
  src: url("https://anchors-files.s3.ap-south-1.amazonaws.com/fonts/Gilroy-Black.ttf") format('truetype');
}
@font-face {
  font-family: 'Gilroy-Bold';
  src: url("https://anchors-files.s3.ap-south-1.amazonaws.com/fonts/Gilroy-Bold.ttf") format('truetype');
}
@font-face {
  font-family: 'Gilroy-Medium';
  src: url("https://anchors-files.s3.ap-south-1.amazonaws.com/fonts/Gilroy-Medium.ttf") format('truetype');
}
@font-face {
  font-family: 'Gilroy-Light';
  src: url("https://anchors-files.s3.ap-south-1.amazonaws.com/fonts/Gilroy-Light.ttf") format('truetype');
}
@font-face {
  font-family: 'Gilroy-SemiBold';
  src: url("https://anchors-files.s3.ap-south-1.amazonaws.com/fonts/Gilroy-SemiBold.ttf") format('truetype');
}
@font-face {
  font-family: 'Gilroy-Regular';
  src: url("https://anchors-files.s3.ap-south-1.amazonaws.com/fonts/Gilroy-Regular.ttf") format('truetype');
}
@font-face {
  font-family: 'Gilroy Medium';
  src: url('https://anchors-assets.s3.ap-south-1.amazonaws.com/fonts/Gilroy-Medium.ttf') format('truetype');
}
