@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

.model {
  width: 82vw;
  height: 100vh;
  background-color: #ffffff46;
  position: fixed;
  top: 0;
  margin-left: -40px;
  display: flex;
  align-items: center;
  z-index: 9999;
  justify-content: center;
  font-family: "Roboto", sans-serif;
}

.model_delete {
  width: 100vw;
  height: 100vh;
  background-color: #ffffff0e;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 9999;
  justify-content: center;
  font-family: "Roboto", sans-serif;
}
.model_main_box_delete {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
  max-width: 550px;
  height: 500px;
  /* margin-top: 20vh; */
  /* margin-left: 17vw; */
  border-radius: 9px;
}
.model_question_delete {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 600;
}
.model_button_delete {
  display: flex;
  justify-content: space-between;
  width: 80%;
}
.model_button1_delete {
  width: 157px;
  height: 47px;
  font-size: 1.2rem;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  border-radius: 5px;
  border: 2px solid #000000ba;
  cursor: pointer;
  transition: transform 0.5s ease;
}
.model_button2_delete:nth-child(2) {
  width: 157px;
  height: 47px;
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.5s ease;
  background-color: rgb(0, 0, 0);
  border: 2px solid rgb(0, 0, 0);
  color: rgb(255, 255, 255);
}
.deletemodal_wrap {
  height: 100%;
  padding: 10% 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.model_logout {
  font-family: "Roboto", sans-serif;
  width: 72%;
  height: 93vh;
  background-color: #84808082;
  position: absolute;
  left: 19vw;
  top: 2vh;
}

.document_preivew_model_logout_workshop {
  font-family: "Roboto", sans-serif;
  width: 72%;
  height: 93vh;
  background-color: #84808082;
  position: absolute;
  left: 19vw;
  top: 2vh;
}

.logout_model_logout {
  width: 100vw;
  height: 100vh;
  background-color: #8c8c8c57;
  position: fixed;
  z-index: 9999;
  display: grid;
  place-items: center;
  top: 0;
  left: 0;
}
.logout_model_logout_workshop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  display: grid;
  place-items: center;
}
.model_main_box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 530px;
  height: 300px;
  padding: 40px 80px;
  border-radius: 10px;
  background: #121212;
  box-sizing: border-box;
}

.model_question {
  font-weight: bold;
  text-align: center;
  color: #d0d0d0;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.model_gyan {
  margin-top: -76px;
  text-align: center;
  color: #a0a0a0;
  text-align: center;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.model_buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.model_button {
  width: 157px;
  height: 47px;
  color: var(--success-green-700, #00cd93);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid var(--success-green-700, #047857);
  box-shadow: 0px 4px 8px 0px rgba(31, 31, 31, 0.1);
  cursor: pointer;
}

.model_button:nth-child(2) {
  border-radius: 4px;
  border: 1px solid #f00;
  box-shadow: 0px 4px 8px 0px rgba(31, 31, 31, 0.1);
  color: red;
}

.model_button:hover {
  background-color: red;
  color: white;
  border: 1px solid red;
}
.model_button1_delete:hover {
  transform: scale(1.05);
}
.model_button2_delete:hover {
  transform: scale(1.05);
}

.loading_bar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  background-color: rgba(248, 245, 245, 0.984);
  z-index: 999;
}

.loader_home {
  width: 5rem;
  display: inline-block;
  margin: auto;
  -webkit-animation: rotating 1.5s linear infinite;
}

.loadbar2 {
  position: fixed;
  top: 21vh;
  bottom: 2vh;
  left: 19vw;
  right: 5vw;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent !important;
}

.loadbar3 {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}

.loading_strip_container{
  border-radius: 8px;
background: var(--natural-900, #212121);
padding: 40px 40px;
display: flex;
align-items: center;
gap: 10px;
}

.loading_strip_container > span{
  color: var(--natural-50, #FAFAFA);
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
width: 200px;
}

.load2 {
  width: 2rem ;
}

.fb_modal {
  position: fixed !important;
  width: 100vw;
  height: 150vh;
  top: 0;
  left: 0;
}

.fb_modal_main_box {
  margin-top: 10vh;
  /* margin-left: 30vw; */
  width: 600px;
  height: 380px;
  padding: 10px;
  border-radius: 10px !important;
  font-family: "Roboto", sans-serif;
  animation: popout 0.7s ease-in-out forwards;
  opacity: 0;
}

.fb_span_one {
  font-size: 1.4rem;
  text-align: center;
  font-weight: 700;
  margin: 0;
  padding: 0;
  color: rgb(81, 79, 79);
  width: 80%;
  margin-top: -35px;
}

.fb_span_two {
  width: 80%;
  font-size: 1.1rem;
  color: rgb(81, 79, 79);
  /* text-align: center; */
  word-wrap: break-word;
  font-family: "Roboto", sans-serif !important;
  margin: 0;
  padding: 0;
  font-weight: 400;
  margin-bottom: -20px;
}

.fb_span_two span {
  margin-right: 10px;
}

.fb_span_two input[type="number"] {
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(218, 217, 217);
  padding: 10px 8px;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  width: 160px;
}

.fb_span_two input:focus {
  outline: none;
}

.stars_model {
  height: max-content;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.fb_modal_main_box textarea {
  width: 75%;
  min-height: 20%;
  border-radius: 10px;
  resize: none;
  padding: 15px;
  margin-bottom: -10px;
  font-size: 1rem;
  max-height: 35%;
  border: 1px solid rgb(218, 217, 217);
}

.fb_modal_main_box textarea:focus {
  outline: none;
}

.stars_model i {
  color: #b7b6b6d4;
  border: 1px solif black;
  cursor: pointer;
}

.fb_model_button {
  width: 40%;
  font-weight: 700;
  font-size: 1.3rem;
  border-radius: 10px;
  margin: auto !important;
  background-color: red;
  color: white;
  border: 1px solid red;
}

.fb_modal_main_box > i {
  position: relative;
  left: 16vw;
  bottom: 10px;
  color: rgb(215, 214, 214);
  transition: transform 0.3s ease;
  cursor: pointer;
}

/* first time Modal ------------------- */

.firstTimeModal_main_box {
  width: 40vw;
  padding: 40px;
  border-radius: 16px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
}

.firstTimeModal_main_box > h2 {
  color: var(--neutral-gray-800, #1e293b);
  text-align: center;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 80%;
}

.firstTimeModal_main_box > section {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  width: 85%;
}

.firstTimeModal_main_box > section > div {
  display: flex;
  align-items: center;
  gap: 8px;
}

.firstTimeModal_main_box > section > div > section {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.firstTimeModal_main_box > section h3 {
  color: var(--neutral-gray-600, #475569);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.firstTimeModal_main_box > section p {
  color: var(--neutral-gray-600, #475569);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.firstTimeModal_main_box > button {
  padding: 12px 20px;
  border-radius: 4px;
  background: #f00;
  color: #fff;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  cursor: pointer;
}

@keyframes popout {
  from {
    position: absolute;
    opacity: 0.4;
    bottom: 10px;
  }

  to {
    position: absolute;
    opacity: 1;
    bottom: 25vh;
  }
}

.fb_modal_main_box i:hover {
  color: darkorange;
}

/* Email Modal */

.email_box {
  width: 60vw;
  height: 90vh;
  position: fixed;
  top: 3vh !important;
  background-color: white;
}

.email_form {
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  height: max-content;
}

.email_form > div {
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: space-between;
  margin-bottom: 15px;
}

.email_box label {
  color: rgba(112, 112, 112, 0.875);
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 8px;
}

.email_box div input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 2px solid rgba(199, 199, 202, 0.599);
  font-size: 1.1rem;
  padding: 2px 8px;
  box-sizing: border-box;
  -webkit-user-select: none;
}

.email_box div input:focus {
  outline: none;
}

.editorinemail {
  max-height: 42vh;
  overflow-y: scroll;
}

.email_subs {
  color: rgb(44, 188, 44);
  font-size: 1.2rem;
  text-align: left;
  font-weight: 500;
  width: 80%;
  margin-top: -25px;
}

.email_button {
  background-color: red;
  color: white;
  border: none;
  font-weight: 700;
  padding: 0 25px;
}

.mail_content {
  width: 100%;
  /* height: 40px; */
  border-radius: 5px;
  border: 2px solid rgba(199, 199, 202, 0.599);
  font-size: 1.1rem;
  padding: 2px 8px;
  box-sizing: border-box;
}

/* thaks model css -------------------------------------------------------------------*/
.thanks_model {
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px;
  justify-content: space-evenly;
  font-family: "Roboto", sans-serif;
}

.thanks_model > i {
  position: relative;
  left: 250px;
  top: -8px;
  color: gray;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.thanks_model > i:hover {
  transform: scale(1.18);
  color: red;
}

.workshops_create_model {
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 50vw;
  padding: 18px;
  justify-content: space-evenly;
  font-family: "Roboto", sans-serif;
  position: relative;
  right: 10vw;
  top: -10vw;
}

.workshops_create_model > i {
  position: relative;
  left: 250px;
  top: -8px;
  color: gray;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.workshops_create_model > i:hover {
  transform: scale(1.18);
  color: red;
  cursor: pointer;
}

.workshop_created_model {
  font-size: 1.4rem;
  font-weight: 500;
  margin: 10px 0;
  text-align: center;
}
.workshop_created_model svg {
  padding-bottom: 10px;
}
.workshop_create_share_btn svg {
  padding-right: 25px;
}
.workshop_create_share_btn {
  background-color: #000000;
  color: #fff;
}
.workshop_create_model_button {
  margin-top: 30px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 110%;
}
.workshop_create_model_button button {
  font-size: 1.2rem;
  font-weight: 700;
  color: white;
  padding: 15px;
  border: none;
  border-radius: 5px;
  margin: 10px 15px;
  cursor: pointer;
}
.workshop-created-btns {
  margin-top: 30px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 110%;
}
.atcb {
  all: unset;
}

.workshop_create_share_btn {
  font-size: 1.2rem;
  font-weight: 700;
  color: white;
  padding: 15px;
  border: none;
  border-radius: 5px;
  margin: 10px 15px;
  cursor: pointer;
}

.workshop_create_model_content {
  color: #6a6161;
  font-size: 1.2rem;
  text-align: center;
  margin: 10px 0;
  font-weight: 400;
  max-width: 50vw;
  padding-top: 20px;
  margin: 0vw 10vw;
}

.thanks_model_header {
  font-size: 1.4rem;
  font-weight: 500;
  margin: 10px 0;
  text-align: center;
}

.thanks_model_header i {
  margin-bottom: 40px;
}

.thanks_model_content {
  color: #6a6161;
  font-size: 1.2rem;
  text-align: center;
  margin: 10px 0;
  font-weight: 400;
}

.thanks_model_button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 110%;
}

.thanks_model_button button {
  font-size: 1.2rem;
  font-weight: 700;
  color: white;
  padding: 15px;
  border: none;
  border-radius: 5px;
  margin: 10px 15px;
  cursor: pointer;
}

.whatsapp_btn {
  background-color: #25d366;
}

.linkedin_btn {
  border-radius: 8px;
  background: #f00;
}

/* social proof css --------------------------------------------------------------------- */

.proof_box {
  display: flex;
  align-items: center;
  position: fixed;
  right: 20px;
  z-index: 9999;
  opacity: 0;
  top: 80px;
  animation: socialIn 0.7s ease-in-out forwards;
}

.proof_img {
  width: 2rem;
  height: 2rem;
  -webkit-animation: rotating 1.5s linear infinite;
}

.proof_box_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  width: max-content;
  padding: 8px;
  padding-left: 30px;
  background-color: rgba(70, 68, 68, 0.96);
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  margin-left: -8px;
  letter-spacing: 2px;
  color: white;
}

.proof_box_content i {
  cursor: pointer;
  margin-left: 30px;
}

.proof_user_name {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 150px;
}

.proof_box_content p {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.proof_box_content span {
  color: rgb(131, 245, 227);
  font-weight: bold;
}

.proof_box > span {
  background-color: rgba(70, 68, 68, 0.96);
  padding: 17px;
  border-radius: 150px;
}

/***** REQUEST RESOURCES MODAL POPUP ******************/

.main-req-resource-cover {
  /* width: 100vw;
    height: 100vh;
    background-color: #a0a0a039;
    position: fixed;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center; */

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.request-resources-modal-container {
  position: absolute;
  bottom: 0;
  background-color: #121212;
  transform: translate(-50%, -50%);
  padding: 40px 10%;
  width: 100vw;
  box-sizing: border-box;
  border-radius: 5px;
  animation: popoutModal 0.5s forwards ease-in-out;
  display: flex;
  flex-direction: column;
}

@keyframes popoutModal {
  0% {
    opacity: 0;
    transform: translateY(500px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.req-resource-popup-cancel {
  opacity: 0.4;
}
.req-res-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-family: "Gilroy-Bold", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: bold;
  margin-bottom: 20px;
}
.req-res-body-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.req-res-body-first {
  color: #f1f5f9;
  font-family: "Gilroy-Medium", sans-serif;
  font-size: 24px;
  font-style: normal;
  line-height: normal;
}
/* .req-res-body-second {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  identical to box height
  margin-bottom: 15px;

  color: #000000;
} */

.modal_frame_01_types {
  margin-top: 20px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 8px;
  background: #212121;
}

.modal_frame_01_types_inside_01 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.modal_frame_01_types_inside_01_text {
  color: #f1f5f9;
  font-family: "Gilroy-Medium", sans-serif;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
}
.modal_frame_01_types_inside_01_frame {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}
.modal_frame_01_types_inside_01_frame_01 {
  display: flex;
  padding: 8px 20px;
  width: 70px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid var(--neutral-gray-600, #475569);
  background: #212121;
  color: #94a3b8;
  cursor: pointer;
}

.modal_frame_01_types_inside_01_frame > svg {
  color: #94a3b8;
}

.modal_frame_01_types_inside_01_frame01 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  margin-top: 8px;
}

.modal_frame_01_types_inside_01_frame01_text {
  color: var(--neutral-gray-100, #f1f5f9);
  font-family: "Gilroy-Medium", sans-serif;
  font-size: 20px;
  font-style: normal;
}

.modal_frame_01_types_inside_01_frame01_textarea {
  gap: 10px;
  border-radius: 4px;
  width: 95%;
}

.modal_frame_01_types_inside_01_frame01_textarea > input {
  width: 100%;
  padding: 16px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid var(--neutral-gray-600, #475569);
  color: white;
  font-family: "Gilroy-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  width: 95%;
}

.modal_frame_01_types_inside_01_frame01_textarea > input:focus {
  outline: none;
}

.modal_frame_01_types_inside_01_frame01_textarea > input::placeholder {
  color: grey;
}

.modal_frame_01_types_inside_01_frame02 {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  align-self: stretch;
}
.modal_frame_01_types_inside_01_frame02_01 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  margin-top: 12px;
}
.req-res-body-wrap textarea {
  width: 100%;
  height: 122px;
  left: 49px;
  top: 164px;

  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 10px;
  background-color: rgb(0, 0, 0, 0);
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  color: rgba(0, 0, 0);
  margin-bottom: 31px;
}
.modal_frame_01_types_inside_01_frame02_01_text {
  color: var(--neutral-gray-100, #f1f5f9);
  font-family: "Gilroy-Medium", sans-serif;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
}
.modal_frame_01_types_inside_01_frame02_01_cost {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}
.modal_frame_01_types_inside_01_frame02_01_cost1 {
  cursor: pointer;
  display: flex;
  padding: 16px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--neutral-gray-600, #475569);
  color: var(--neutral-gray-100, #f1f5f9);
  font-family: "Gilroy-Medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  height: 15px;
}
.modal_frame_01_types_inside_01_frame02_button {
  display: flex;
  padding: 16px 20px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: #f00;
  color: #fff;
  font-family: "Gilroy-SemiBold", sans-serif;
  font-size: 20px;
  font-style: normal;
  cursor: pointer;
}
.req-res-text-amount {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  margin-bottom: 25px;
  color: #000000;
}

.req-res-amount-select {
  border: 1px solid #00000069;
  border-radius: 4px;
  padding: 5px;
  margin-right: 10px;
  cursor: pointer;
  padding: 2px 5px;
}

.req-res-body-select-amount {
  margin-bottom: 60px;
}
.req-res-submit-btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.req-res-submit-btn {
  width: 137px;
  height: 37px;
  left: 218px;
  top: 470px;
  border: none;
  cursor: pointer;

  background: #000000;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 21px;
  gap: 10px;
  border-radius: 8px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.req-res-input {
  border: 1px solid grey;
  border-radius: 5px;
  padding: 16px;
  width: 50px;
  text-align: center;
}
/***** REQUEST RESOURCES MODAL POPUP END ******************/

/* Feedback modal csss -------------------------------------- */
.feedback_modal_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
}

.close_button_modal {
  color: #878282;
  font-size: 20px;
  cursor: pointer;
}

.feedback_modal_main_container {
  display: flex;
  width: 100%;
  height: 194px;
  padding: 3% 10%;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  flex-shrink: 0;
  background: #121212;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.1);
  border-radius: 13px 13px 0 0;
  animation: popoutModal 0.7s forwards ease-in-out;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
}

.fb_messaging_section_modal_last {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}
@keyframes popoutModal2 {
  0% {
    opacity: 0;
    transform: translate(0%, 0%) scale(0.5);
  }

  100% {
    opacity: 1;
    transform: translate(0%, 0%) scale(1);
  }
}

.text_01_feedback_modal {
  color: #fff;
  font-family: "Gilroy-Medium";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.text_02_feedback_modal {
  color: var(--neutral-gray-100, #f1f5f9);
  font-family: "Gilroy-SemiBold";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
}

.text_03_feedback_modal {
  color: var(--neutral-gray-100, #f1f5f9);
  font-family: "Gilroy-SemiBold";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.rating_section_feedback_modal {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.rating_section_feedback_modal > section {
  display: flex;
  align-items: center;
  gap: 8px;
}

.rating_fb_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  cursor: pointer;
  fill: white;
  color: white;
}

.selected_star {
  fill: #eac400;
  font-size: 20px;
  color: white;
}

.unselected_star {
  fill: white;
  color: white;
  font-size: 20px;
}

.fb_messaging_section_modal {
  width: 413px;
  height: 114px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  color: var(--neutral-gray-700, #334155);
}

.fb_modal_comment {
  color: var(--neutral-gray-700, #334155);
  width: 421px;
  height: 99px;
  border: none;
  padding: 10px;
  resize: none;
  border-radius: 8px;
  border: 1px solid var(--neutral-gray-600, #475569);

  font-family: "Gilroy-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  background: #121212;
}

.fb_modal_button_submit {
  display: flex;
  padding: 16px 20px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: #f00;
  color: #fff;
  font-family: "Gilroy-SemiBold";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Preview Section Css ----------------------------------------------------------------- */

.preview_model_box {
  /* background-color: rgba(238, 238, 238, 0.575); */
}

.preview_model > i {
  position: relative;
  left: 420px;
  cursor: pointer;
  color: gray;
  top: 32px;
  transition: all 0.3s ease-in-out;
}

.preview_model > i:hover {
  color: red;
  transform: scale(1.2);
}

.preview_model > span {
  margin-top: 30px;
  margin-bottom: 25px;
}

.preview_section {
  width: 99%;
  overflow-y: auto;
}

.preview_section img {
  width: 100%;
  border-radius: 10px;
}

.preview_section > div {
  margin: auto;
  margin-top: 20px;
  width: 92%;
}

.preview_section h1 {
  font-size: 1.7rem;
  margin-bottom: 10px;
}

.preview_section h2 {
  font-size: 1.4rem;
  margin-bottom: 10px;
}

.preview_section p {
  font-size: 1.1rem;
  line-height: 21px;
  width: 98%;
  margin: auto;
}

.loadbar2_preview_popup {
  position: fixed;
  top: 7vh;
  bottom: 2vh;
  left: 5vw;
  right: 5vw;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loadbar2_preview_wrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.loader_home_preview {
  width: 20%;
  display: inline-block;
  margin: auto;
  -webkit-animation: rotating 1.5s linear infinite;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(99, 96, 96, 0.8);
}

/*** image preview and resize */
.imagePreviewModal {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 9999;
}

.pdfviewerpopup {
  margin: 20% 10%;
}

@media screen and (max-width: 650px) {
  .react-pdf__Page__canvas {
    width: 95vw !important;
    aspect-ratio: 9/16 !important;
  }
  .document_preivew_create_model {
    padding-left: unset;
    padding-right: unset;
  }
  .next {
    margin-right: unset;
    opacity: 0.5;
  }
  .prev {
    opacity: 0.5;
  }
  .top_document_preivew {
    padding-right: 10px;
  }
}
@media screen and (max-width: 500px) {
  .react-pdf__Page__canvas {
    aspect-ratio: 9/15 !important;
    max-height: 90vh;
  }
}
@media screen and (max-width: 440px) {
  .react-pdf__Page__canvas {
    aspect-ratio: 9/14 !important;
    max-height: 90vh;
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes socialIn {
  0% {
    opacity: 0;
    position: fixed;
    right: 0px;
  }
  100% {
    position: fixed;
    opacity: 100;
    right: 30px;
  }
}

@keyframes socialOut {
  0% {
    opacity: 0;
    position: fixed;
    top: 0px;
  }
  100% {
    position: fixed;
    opacity: 100;
    top: 60px;
  }
}

@media (max-width: 600px) {
  .model_main_box {
    width: 93vw;
    height: max-content;
    position: absolute;
    margin-left: 0;
    text-align: center;
    padding: 10px 20px;
  }

  .model_question {
    font-size: 18px;
    margin: 16px 0;
  }

  .model_gyan {
    font-size: 14px;
    width: 80%;
    margin: 3px 0;
  }

  .model_buttons button {
    width: 17vw;
    margin: 20px 0;
    font-size: 14px;
    height: 37px;
  }

  .fb_modal {
    padding: 0;
    padding-left: 7px;
  }

  .fb_model_main_box {
    width: 60vw !important;
  }

  .fb_modal_main_box > i {
    left: 40vw;
    top: 12px;
  }

  .fb_span_one {
    margin: 15px 0;
    font-size: 1.1rem;
    width: 90%;
  }

  .request_model_span {
    text-align: left;
  }

  .fb_span_two {
    font-size: 0.9rem;
    text-align: left;
    width: 90%;
    margin-bottom: 5px;
  }

  .fb_span_two input[type="number"] {
    font-size: 0.9rem;
  }

  .stars_model {
    width: 98%;
    margin: 0;
    margin-top: 15px !important;
  }

  .stars_model i {
    font-size: 1.4rem;
  }

  .fb_modal_main_box textarea {
    width: 85%;
    font-size: 0.9rem;
    padding: 9px;
    min-height: 15vh;
    max-height: 30vh;
    margin: 15px 0;
    border: 1px solid rgb(214, 214, 214);
    margin-top: 30px;
  }

  .fb_model_button {
    width: 60% !important;
    font-size: 1.1rem;
    margin-bottom: 10px !important;
  }

  .thanks_model {
    display: flex;
    border-radius: 14px;

    position: relative;
    top: 70%;
    width: 1367px;
    padding: 40px 144px;

    background: #121212;
    padding: 8px;
    width: 85vw;
  }

  .thanks_model > i {
    top: 16px;
    left: 130px;
  }

  .thanks_model_header {
    color: #fff;
    text-align: center;
    font-family: "Gilroy-Bold";
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
  }
  .thanks_model_content {
    color: var(--neutral-gray-100, #f1f5f9);
    text-align: center;
    font-family: "Gilroy-Medium";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
  }
  .thanks_model_button {
    width: 100%;
  }
  .thanks_model_button button {
    font-size: 1.1rem;
    padding: 13px;
  }

  /* social proof mobile version */

  .proof_box {
    right: 0px;
    top: 30px;
    /* opacity: 100%; */
    animation: socialOut 0.6s ease-in-out forwards;
  }

  .proof_img {
    width: 1.1rem;
    height: 1.1rem;
  }

  .proof_box_content {
    letter-spacing: 1px;
    font-size: 0.8rem;
    padding: 5px;
    padding-left: 12px;
  }

  .proof_box > span {
    padding: 10px;
  }

  .proof_user_name {
    width: 1.1rem;
    height: 1.1rem;
  }

  .proof_box_content i {
    margin-left: 10px;
  }

  /* request model box */

  .request_model_box {
    margin-top: 10px !important;
  }

  .request_model_button {
    margin-top: 13px;
  }

  .loadbar2 {
    top: unset;
    left: unset;
    bottom: unset;
    right: unset;
    width: 100vw;
    height: 100vh;
  }

  /* Feedback modal ---------------------------- */

  .feedback_modal_main_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 328px;
    padding-top: 35px;
    box-sizing: border-box;
    padding: 20px;
    gap: 12px;
  }

  .close_button_modal {
    padding: 40px 114px;
    font-size: 16px;
    display: flex;
    padding: 5px;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
  }

  .fb_messaging_section_modal {
    width: 100%;
  }

  .fb_modal_comment {
    min-width: 296px;
    width: 100%;
    height: 99px;
    box-sizing: border-box;
    font-size: 11px;
  }

  .rating_section_feedback_modal > section {
    gap: 8px;
  }

  .text_01_feedback_modal {
    font-size: 16px;
  }
  .text_02_feedback_modal {
    font-size: 13px;
  }
  .text_03_feedback_modal {
    font-size: 12px;
    width: 95%;
  }

  .rating_fb_container > svg {
    font-size: 12px;
  }

  .fb_modal_button_submit {
    margin: 0 auto;
    font-size: 12px;
  }
}

.feedback_modal_first {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  background: #121212;
  width: 100%;
}
.feedback_modal_first_01 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
}

.feedback_modal_first_01_text {
  color: #fff;
  font-family: "Gilroy-Medium", sans-serif;
  font-size: 24px;
  font-style: normal;
  line-height: normal;
}

.feedback_modal_first_01_name {
  color: var(--neutral-gray-100, #f1f5f9);
  font-family: "Gilroy-SemiBold", sans-serif;
  font-size: 30px;
  font-style: normal;
  line-height: normal;
}

.feedback_modal_second {
  width: 100%;
  height: 100%;
}

.feedback_modal_second > textarea {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 16px;
  resize: none;
  color: #ffffff;
  background-color: transparent;
  font-family: "Gilroy-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  border: 1px solid #475569;
}

.feedback_modal_second > textarea::placeholder {
  color: grey;
}

.feedback_modal_second > textarea:focus {
  outline: none;
}

.feedback_modal_third {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 20px;
  align-self: stretch;
}

.feedback_modal_third_close {
  width: 196px;
  display: flex;
  padding: 5px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
}

.feedback_modal_third_button {
  display: flex;
  padding: 16px 20px;
  align-items: flex-start;

  border-radius: 8px;
  background: #f00;
  color: #fff;
  font-family: "Gilroy-SemiBold", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
}

.modal_frame_01_types_inside_01_frame_01 > svg {
  height: 40px;
  width: 40px;
}

@media screen and (max-width: 650px) {
  .req-res-header {
    font-size: 20px;
  }
  .req-res-body-first {
    width: 296px;
    font-size: 16px;
  }
  .modal_frame_01_types {
    gap: 12px;
    padding: 12px;
  }

  .modal_frame_01_types_inside_01_frame_01 {
    width: 40px;
  }

  .modal_frame_01_types_inside_01_text {
    font-size: 16px;
  }
  .modal_frame_01_types_inside_01_frame_01 > svg {
    height: 24px;
    width: 24px;
  }
  .modal_frame_01_types_inside_01_frame_01_text1 {
    font-size: 10px;
  }
  .modal_frame_01_types_inside_01_frame01_text {
    font-size: 16px;
  }
  .modal_frame_01_types_inside_01_frame02_01_text {
    font-size: 16px;
  }

  .modal_frame_01_types_inside_01_frame02_01_cost {
    gap: 9px;
  }

  .modal_frame_01_types_inside_01_frame02_01_cost1 {
    font-size: 12px;
    padding: 8px;
    gap: 4px;
  }
  .modal_frame_01_types_inside_01_frame02_button {
    font-size: 13px;
    gap: 10px;
    padding: 6px 8px;
    white-space: nowrap;
    border-radius: 4px;
    position: absolute;
    right: 20px;
    bottom: 15px;
  }
  .req-res-body-wrap {
    margin-bottom: 25px;
  }

  .req-res-input {
    width: 41px;
    text-align: center;
    padding: 8px;
  }

  .modal_frame_01_types_inside_01_frame01_textarea > input {
    padding: 11px;
  }
}

.thanks_start {
  border-radius: 14px 14px 0 0;
  background: #121212;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 40px 144px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  position: absolute;
  animation: popoutModal 0.5s forwards ease-in-out;
  bottom: 0;
}

.logout_model_logout_first {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}
.logout_model_logout_first_cloud {
  display: flex;
  padding: 13px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  color: white;
}

.logout_model_logout_second {
  color: #fff;
  text-align: center;
  font-family: "Gilroy-Bold", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

.logout_model_logout_third {
  color: var(--neutral-gray-100, #f1f5f9);
  text-align: center;
  font-family: "Gilroy-Medium", sans-serif;
  font-size: 24px;
  font-style: normal;
  line-height: normal;
}

.logout_model_logout_button {
  display: flex;
  padding: 20px 40px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: #f00;
  color: #fff;
  font-family: "Gilroy-Medium", sans-serif;
  font-size: 24px;
  font-style: normal;
  line-height: normal;
  cursor: pointer;
}
.logout_model_logout_first_bscloud {
  width: 80px;
  height: 55px;
}
.logout_model_logout_first_close {
  position: absolute;
  right: 10vw;
}

.logout_model_logout_first_close > i {
  color: grey;
  cursor: pointer;
}

/* Thanks -------------- */

.sharing_link_section_thanks_modal {
  display: flex;
  align-items: center;
  gap: 20px;
}

.sharing_link_section_thanks_modal > section {
  position: relative;
  width: 66vw;
  display: flex;
  align-items: center;
}

.sharing_link_section_thanks_modal > section > input {
  width: 100%;
  background-color: transparent;
  padding: 12px 20px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid var(--neutral-gray-600, #475569);

  color: #94a3b8;
  font-family: "Gilroy-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.sharing_link_section_thanks_modal > section > svg {
  position: absolute;
  right: 10px;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.sharing_link_section_thanks_modal > div {
  display: flex;
  align-items: center;
  gap: 16px;
}

.sharing_link_section_thanks_modal > div span {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background: #212121;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 650px) {
  .thanks_start {
    padding: 20px;
    gap: 20px;
  }
  .logout_model_logout_second {
    font-size: 24px;
  }
  .logout_model_logout_third {
    font-size: 12px;
  }
  .logout_model_logout_button {
    padding: 8px 16px;
    gap: 10px;
    font-size: 16px;
  }
  .logout_model_logout_first_bscloud {
    width: 40px;
    height: 40px;
  }

  .sharing_link_section_thanks_modal {
    flex-direction: column;
  }

  .sharing_link_section_thanks_modal > section {
    width: 88vw;
  }

  .sharing_link_section_thanks_modal > section input {
    font-size: 12px;
  }

  .sharing_link_section_thanks_modal > section svg {
    font-size: 16px;
  }

  .sharing_link_section_thanks_modal > div {
    width: 100%;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 650px) {
  .feedback_modal_first_01_text {
    display: flex;
    font-size: 16px;
  }
  .feedback_modal_first_01_name {
    font-size: 20px;
  }
  @media (max-width: 650px) {
    .feedback_modal_third_close {
      display: none;
    }
  }
  .feedback_modal_third_button {
    padding: 6px 10px;
    font-size: 12px;
    gap: 10px;
    border-radius: 4px;
    width: fit-content;
  }
}
.feedback_modal_third_close01 {
  margin-top: -2px;
  margin-left: 68px;
}

@media screen and (max-width: 650px) {
  .feedback_modal_third_close01 {
    position: absolute;
    right: 20px;
  }

  .feedback_modal_first {
    display: unset;
    margin-top: 13px;
  }

  .feedback_modal_second {
    height: unset;
    width: 100%;
  }

  .feedback_modal_second > textarea {
    height: 80px;
    font-size: 12px;
    padding: 12px;
  }

  .feedback_modal_third {
    display: unset;
  }

  .rating_section_feedback_modal_mobile {
    border-radius: 8px;
    background: #212121;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    height: 200px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }

  /* request resource ------------ */

  .request-resources-modal-container {
    padding: 30px 20px;
    border-radius: 20px 20px 0px 0px;
    background: #121212;
  }

  .mobile_view_modal_horizonal_bar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    left: 0;
  }

  .mobile_view_modal_horizonal_bar > section {
    width: 80px;
    height: 2px;
    background: #94a3b8;
  }
}
