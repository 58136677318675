
/* New ui css landing page -------------------------------------------------------------------- */

html {
  scroll-behavior: smooth;
}

.mainLandingpage_body {
  background-color: #101010;
  /* width: 100vw; */
  overflow-x: hidden;
  box-sizing: border-box;
  /* min-height: 100vh; */
  color: white;
  -webkit-user-select: none;
}

/* hero section ------------------------------------------------------------------------------------------- */

.hero_mainpage {
  display: flex;
  align-items: center;
  width: 92vw;
  margin: 30vh auto;
  justify-content: center;
}

.hero_section_image {
  width: 640px;
  height: 353px;
}

.left_hero_text {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.left_hero_text > div {
  position: relative;
}

.left_hero_text > div > img {
  position: absolute;
  left: -35px;
  top: -14px;
}

.left_hero_text > div > h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 61px;
  /* identical to box height */

  background: linear-gradient(
    90deg,
    #75a4ff -6.71%,
    #ffffff 39.13%,
    #fd1010 93.59%
  );
  background-size: 200% auto;

  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 4s linear infinite;
}
@keyframes shine {
  0% {
    background-position: 0% center;
  }

  60% {
    background-position: 100% center;
  }

  100% {
    background-position: 0% center;
  }
}

.left_hero_text p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 34px;
  line-height: 155.02%;
  color: rgba(255, 255, 255, 0.6);
}

.left_hero_text > section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 25px 0;
  gap: 17px;
}

.left_hero_text > section > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  width: 345px;
  color: rgba(255, 255, 255, 0.5);
}

.left_hero_text button {
  width: 110px;
  height: 42px;
  background: #ffffff;
  border: 0.7px solid #505050;
  border-radius: 29px;
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.left_hero_text button:focus {
  background-color: #404245;
}

/* headers style */

.headers1_mainpage {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  margin-bottom: 15px;
  color: white;
}

.headers2_mainpage {
  font-family: "Clash Display", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 100px;
  margin-bottom: unset;
}

.text_mainpage1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  display: inline-block;
  width: 76%;
  color: rgba(255, 255, 255, 0.8);
}

/* steps anchors page =================================================================================*/

.steps_anchors_mainpage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;
  margin-bottom: 200px;
}

.steps_anchors_mainpage > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80vw;
  margin-top: 50px;
}

.steps {
  border: 1px solid rgba(253, 253, 253, 0.5);
  border-radius: 160px 160px 0px 0px;
  width: 321px;
  height: 343px;
}

.steps > div {
  width: 279px;
  height: 300px;
  background: #1e1e1e;
  border-radius: 160px 160px 0px 0px;
  position: relative;
  left: 20px;
  top: 22px;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
}

.steps > div img {
  position: absolute;
  top: 60px;
  width: 170px;
}

.steps > div p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 145.02%;
  text-align: center;
  width: 64%;
  /* margin-bottom: 60px; */
}

/* why anchors section ----------------------------------------------------------- */

.why_anchors_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 180px auto;
  width: 91vw;
  margin-bottom: 200px;
}

.why_anchors_section > img {
  width: 666px;
  height: 537px;
}

.why_anchors_desc {
  width: 50%;
}

.why_anchors_desc > div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 30px;
  gap: 35px;
  column-gap: 60px;
}

.why_anchors_steps_boxes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.why_anchors_desc > div img {
  width: 48px;
  height: 48px;
  margin-bottom: 15px;
}

.why_anchors_steps_boxes span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}

.why_anchors_steps_boxes p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 15px;
}

/* facilities sectiuon anchors------------------------------------------------------------------- */

.facilities {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
}

.facilities > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 161.02%;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  width: 50%;
  margin-bottom: 26px;
  margin-top: 12px;
}

.facilities_boxes {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 60px;
}

.facilities_boxes > div {
  background: rgba(30, 30, 30, 0.5);
  border: 0.5px solid rgba(253, 253, 253, 0.5);
  border-radius: 9px;
  padding: 12px;
  height: 160px;
  width: 295px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.facilities_boxes > div img {
  width: 33.68px;
  height: 30px;
}

.facilities_boxes > div span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 161.02%;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 25px;
}

.facilities_boxes > div p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 161.02%;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  width: 99%;
  margin-top: 13px;
  margin-bottom: unset;
}

/* Eligibility section mainpage -------------------------------------------------------------------- */

.eligibility_mainpage {
  margin: 132px 0;
  padding-top: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.eligibility_mainpage > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  width: 75%;
}

.eligibility_mainpage > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 178.52%;
  color: #ffffff;
  margin-top: 25px;
}

.eligibility_check_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 25px 0;
}

.eligibility_check_section > section {
  margin-right: 35px;
}

.eligibility_check_section > section span {
  margin-right: 40px;
  padding: 19px 12px;
  padding-bottom: 8px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: white;
}

.eligibility_check_section > section span:hover {
  background: #ff0000;
}

.active_platform {
  background: #ff0000;
}

.eligibility_check_section input {
  border: 1px solid #ffffff;
  border-radius: 10px;
  background-color: transparent;
  width: 218px;
  height: 50px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  color: rgb(255, 255, 255);
  /* padding: 10px 5px; */
  text-align: center;
  box-sizing: border-box;
}

.eligibility_check_section input:focus {
  outline: none;
}

.eligibility_check_section input::placeholder {
  color: rgb(255, 255, 255);
  padding-bottom: 2px;
}

.eligibility_check_section input:focus::placeholder {
  color: transparent;
}

.eligibility_mainpage button {
  padding: 0 20px;
  height: 44px;
  background: #ff0000;
  border: none;
  border-radius: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-top: 22px;
  cursor: pointer;
}

/* Connect with us -------------------- */

.connect_with_us_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80vw;
  margin: 180px auto;
  gap: 20px;
}

.connect_with_us_wrapper > img {
  height: 347px;
}

.connect_with_us_wrapper > div {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  max-width: 50%;
}

.connect_with_us_wrapper > div > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 147.52%;
  color: #ffffff;
  color: rgba(255, 255, 255, 0.8);
}

.connect_with_us_wrapper > div > button {
  padding: 0 20px;
  height: 44px;
  background: #ff0000;
  border-radius: 6px;
  border: none;
  cursor: pointer;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

/* People who trust us --------------------------------------------------------- */

.featured_creators {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 50px 0;
  margin-top: 200px;
}

.featured_creators > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 25px;
  margin-top: 20px;
}

.creator_section_cardsection {
  display: flex;
  justify-content: center;
  gap: 5vw;
  position: relative;
  width: 80%;
}
.creator_section_cardsection_card1 {
  width: 316px;
  border-radius: 23px;
  height: 544px;
  overflow: none;
  cursor: pointer;
  /* border: 3px solid rgba(255, 255, 255, 0.5); */
}

.creator_section_cardsection_card1 img {
  width: 100%;
  height: 100%;
  /* border: 3px solid rgba(255, 255, 255, 0.5); */
  border-radius: 20px;
  /* object-fit: cover; */
}

.creator_section_imagetext {
  position: absolute;
  bottom: 0;
  width: 316px;
  display: flex;
  align-items: center;
  height: 120px;
  border-radius: 0px 0px 20px 20px;
  background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.9;
}

.creator_section_imagewrap {
  display: flex;
  width: 85%;
  margin: 0 auto;
  margin-top: 1vh;
  align-items: center;
  justify-content: space-between;
}
.creator_section_imagetext_text span {
  display: block;
}
.creator_section_imagetext_text span:first-of-type {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 3px;
  letter-spacing: -0.5px;
  text-align: left;
  color: #fffaf3;
}
.creator_section_imagetext_text span:last-of-type {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #d4d4d8;
}
.creator_section_icon {
  background: rgba(255, 255, 255, 0.09);
  border: 2px solid #ffffff;
  box-shadow: 0px 15px 75px rgba(27, 25, 68, 0.3);
  backdrop-filter: blur(13px);
  border-radius: 19px;
  width: 58px;
  height: 55.55px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.nav_buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
  width: 10%;
}

.nav_buttons button {
  width: 60px;
  height: 48px;
  border: 1px solid #ffffff;
  border-radius: 25px;
  background: transparent;
  color: white;
  cursor: pointer;
}

/* Faq main page ---------- */

.main_page_faq {
  margin-bottom: 120px;
}

/* Wall fo love section */

.wall_of_love {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 50px 0;
  margin-top: 200px;
  margin-bottom: 150px;
}

.wall_of_love > div {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 40px;
  width: 85vw;
  column-gap: 3%;
  row-gap: 40px;
}

.feedback_box_mainpage {
  width: 243px;
  min-width: 50%;
  height: 205px;
  background: #171717;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 21px;
  gap: 10px;
  margin: 0 auto;
}

/* .feedback_box_mainpage:nth-child(2n) {
  margin-top: 40px;
} */

.feedback_box_mainpage > section {
  display: flex;
  align-items: center;
}

.feedback_box_mainpage > section > div {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}

.feedback_box_mainpage img {
  width: 60px;
  height: 60px;
  border-radius: 150px;
  margin-right: 15px;
}

.user_name_mainpage {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 178.52%;
  color: #ffffff;
}

.user_email_mainpage {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 178.52%;
  color: #ffffff;
}

.feedback_box_mainpage p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 178.52%;
  color: #ffffff;
  height: 70%;
  overflow-y: auto;
}


.feedback_box_mainpage > p::-webkit-scrollbar {
  width: 5px;
  border-radius: 2px;
}

/* Track */
.feedback_box_mainpage > p::-webkit-scrollbar-track {
  background: #6f6f6fad;
}

/* Handle */
.feedback_box_mainpage > p::-webkit-scrollbar-thumb {
  background: #2c2b2b;
}

/* Handle on hover */
.feedback_box_mainpage > p::-webkit-scrollbar-thumb:hover {
  background: #000000;
}


@media screen and (max-width: 600px) {
  /* hero section */

  .hero_mainpage {
    flex-direction: column;
    margin: 20vh auto;
  }

  .left_hero_text {
    align-items: center;
    margin-bottom: 50px;
  }

  .left_hero_text > div > img {
    width: 23px;
    left: -19px;
    top: -9px;
  }

  .left_hero_text > div > h1 {
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
  }

  .left_hero_text p {
    font-weight: 300;
    font-size: 16px;
    line-height: 155.02%;
    text-align: center;
    /* margin: 16px 0; */
    width: 268px;
  }

  .left_hero_text > section > img {
    width: 186px;
    height: 38.08px;
  }

  .left_hero_text > section > span {
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    width: 232px;
  }

  .left_hero_text button {
    width: 110px;
    height: 40px;
    background: #ffffff;
    border: 0.7px solid #505050;
    border-radius: 29px;

    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }

  .hero_section_image {
    width: 310px;
    height: 196px;
  }

  /* header texts */

  .headers1_mainpage {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    width: 80%;
    color: white;
    margin: 0 auto;
    margin-bottom: 25px;
  }

  .headers2_mainpage {
    font-size: 50px;
  }

  .text_mainpage1 {
    text-align: center;
    display: none;
  }

  /* steps section ----------------------------------------------------------------- */

  .steps_anchors_mainpage {
    margin-bottom: 115px;
  }

  .steps_anchors_mainpage > div {
    flex-direction: column;
    margin-top: 30px;
  }

  .steps {
    width: 215px;
    height: 235px;
    margin-bottom: 60px;
  }

  .steps > div {
    width: 175px;
    height: 195px;
    left: 20px;
    top: 21px;
    gap: 10px;
  }

  .steps > div img {
    width: 94px;
    top: 38px;
  }

  .steps > div p {
    font-size: 16px;
    width: 90%;
  }

  /* why anchors section */

  .why_anchors_section {
    flex-direction: column;
    margin: 160px auto;
    margin-top: -50px;
  }

  .why_anchors_section > img {
    display: none;
  }

  .why_anchors_desc {
    width: unset;
  }

  .why_anchors_desc > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
  }

  .why_anchors_desc > div img {
    width: 22px;
    height: 22px;
  }

  .why_anchors_steps_boxes {
    align-items: center;
    margin-bottom: 40px;
  }

  .why_anchors_steps_boxes span {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-top: 8px;
  }

  .why_anchors_steps_boxes p {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    width: 52%;
  }

  /* facilities  */

  .facilities {
    margin-top: -35px;
  }

  .facilities > span {
    display: none;
  }

  .facilities_boxes {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 45px;
    gap: 22px;
  }

  .facilities_boxes > div {
    width: 285px;
    height: 161px;
    background: rgba(57, 58, 61, 0.5);
  }

  .facilities_boxes > div img {
    width: 30px;
    height: 30px;
  }

  .facilities_boxes > div span {
    font-weight: 700;
    font-size: 18px;
    line-height: 161.02%;
  }
  .facilities_boxes > div p {
    width: 90%;
  }

  /* eligibility */

  .eligibility_mainpage {
    /* padding-top: 10p; */
    margin: 120px 0;
  }

  .eligibility_mainpage > p {
    width: 80%;
    font-weight: 400;
    font-size: 14px;
    line-height: 147.52%;
    margin-top: 25px;
  }

  .eligibility_mainpage > span {
    font-weight: 500;
    font-size: 12px;
    line-height: 178.52%;
    margin-bottom: 13px;
  }

  .eligibility_check_section {
    flex-direction: column;
    margin: 16px 0;
  }

  .eligibility_check_section input {
    width: 181px;
    font-weight: 300;
    font-size: 14px;
    line-height: 178.52%;
    height: 39px;
  }

  .eligibility_check_section > section {
    margin-right: unset;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    width: 137%;
    justify-content: space-evenly;
    gap: 8px;
  }

  .eligibility_check_section > section span {
    margin-right: unset;
    color: white;
    font-size: 20px;
    padding: 8px 12px;
  }

  .eligibility_check_section > section span i {
    font-size: 29px;
  }

  .eligibility_mainpage button {
    width: 170px;
    height: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  /* Connect with us ------------------------- */

  .connect_with_us_wrapper {
    width: 95vw;
    flex-direction: column;
    margin: 120px auto;
  }

  .connect_with_us_wrapper > img {
    width: 271px;
    height: 241px;
  }

  .connect_with_us_wrapper > div {
    align-items: center;
    width: 95%;
    gap: 27px;
    max-width: unset;
  }

  .connect_with_us_wrapper > div > h2 {
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
  }

  .connect_with_us_wrapper > div > p {
    font-weight: 400;
    font-size: 14px;
    line-height: 147.52%;
    text-align: center;
  }

  .connect_with_us_wrapper > div > button {
    width: 153px;
    height: 40px;

    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  /* featured creators ---------------------------------------------------------------------------------------*/

  .featured_creators {
    margin-top: 125px;
  }

  .featured_creators > span {
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    margin-top: 10px;
    width: 330px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 121.52%;
    justify-content: center;
  }

  .nav_buttons {
    width: 32%;
    margin-top: 36px;
  }

  .creator_section_cardsection {
    width: 95%;
  }

  .creator_section_cardsection_card1 {
    margin: auto;
    width: 209.7px;
    height: 361px;
  }

  .creator_section_imagetext {
    width: 209.7px;
    height: 80px;
  }

  .creator_section_imagetext_text span:first-of-type {
    font-weight: 600;
    font-size: 11.9449px;
    line-height: 16px;
  }

  .creator_section_imagetext_text span:last-of-type {
    font-weight: 400;
    font-size: 9.29051px;
    line-height: 15px;
  }

  .creator_section_icon {
    width: 38.49px;
    border-radius: 12.6085px;
    height: 36.86px;
  }

  .creator_section_icon > svg {
    width: 19.73px;
    height: 19.91px;
  }

  .nav_buttons button {
    width: 52px;
    height: 34px;
    border-radius: 25px;
  }

  .nav_buttons button i {
    font-size: 18px;
  }

  /* walls of love */

  .wall_of_love > div {
    grid-template-columns: repeat(1, 1fr);
    width: unset;
    row-gap: unset;
  }

  .feedback_box_mainpage {
    margin-bottom: 40px;
    width: 246px;
    background: #2e2f31;
    height: 189px;
  }

  .feedback_box_mainpage:nth-child(2n) {
    margin-top: unset;
  }

  .wall_of_love {
    margin-bottom: 40px;
    margin-top: 115px;
  }

  .user_name_mainpage {
    font-weight: 600;
    font-size: 16px;
    line-height: 178.52%;
  }
}
