.arrow_inner_body_section_home{
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 400px;
  width: 100%;
  position: relative;
}

.image-container_arrow {
  position: absolute;
  display: inline-block;
  width: 30vw;
  display: flex;
  align-items: center;
}

.image-container_arrow:first-child{
  left: -15vw;
}

.image-container_arrow:last-child{
  right: -15vw;
}

.image-container_arrow > img{
  width: 100%;
  object-fit: cover;
}

.inside_arrow_images-text {
  width: 48%;
  position: absolute;
  left: 16%;
  color: #d0d0d0;
  text-align: center;
  font-family: " Gilroy-Medium",sans-serif;
  font-size: 28px;
  font-style: normal;
}



/* .arrow img:nth-child(1) {
    animation: slideInMarginRight 2s ease-in-out ;
  }
  
  .arrow img:nth-child(2) {
    animation: slideInMarginRight 2s ease-in-out 1s ;
  }
  
  .arrow img:nth-child(3) {
    animation: slideInMarginRight 2s ease-in-out 2s ;
  }
  
  @keyframes slideInMarginRight {
    0% {
      margin-right: -500px;
      opacity: 1;
    }
    100% {
      margin-right: 5px;
      opacity: 1;
    }
  }
   */
