/* header section profile -------------------------------------------------- */

.top_header_creator_profile {
  background-color: #232426;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 70px;
  box-sizing: border-box;
  position: relative;
  justify-content: space-between;
  -webkit-user-select: none;
}

.logo_main_page {
  width: 200px;
  cursor: pointer;
}

.top_header_creator_profile > button {
  width: 89px;
  height: 27px;
  border: 0.8px solid #ffffff;
  border-radius: 38px;
  background-color: transparent;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  cursor: pointer;

  margin-right: 47px;
}

.top_header_creator_profile > span {
  color: white;
  font-size: 16px;
  font-weight: 500;
}

.logout_button_header {
  cursor: pointer;
  background-color: white;
  font-size: 20px;
  font-weight: 600;
  padding: 10px;
  border-radius: 5px;
  border: none;
  position: absolute;
  right: 60px;
  bottom: -50px;
  z-index: 999;
  box-shadow: 0px 0px 5px 5px #6d6a6a2e;
}

.hamburger_menu_profile {
  position: absolute;
  /* bottom: -20px; */
  z-index: 999;
  background: #2d2d2d;
  width: 100%;
  color: white;
  left: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 41px;
  top: 100%;
  list-style: none;
  font-size: 18px;
  text-align: center;
}

.hamburger_menu_profile > li {
  width: 100%;
}

/* Profile image ------------------- */

.navbar_user_profile {
  width: 36px;
  height: 36px;
  border-radius: 100px;
  cursor: pointer;
}

/* Clicked options ------------------- */

.listing_options_navbar {
  position: absolute;
  width: 125px;
  /* height: 65px; */
  padding: 12px 20px;
  top: 93%;
  right: 7%;
  background: #282828;
  z-index: 9999;
  color: white;

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 13px;

}

.listing_options_navbar > span {
  font-family: "Gilroy-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.listing_options_navbar > span:hover {
  color: rgba(218, 213, 213, 0.67);
}

/* Navbar 2 css -------- */

/* Header section --------- */

.header_section_new_ui_event_page {
  display: flex;
  width: 100%;
  padding: 12px 10%;
  padding-top: 20px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  position: relative;
}

.header_section_new_ui_event_page > div > img {
  width: 132.107px;
  height: 36px;
}

.header_section_new_ui_event_page > button {
  border-radius: 40px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 10px 20px;
  background: transparent;

  color: #fff;
  font-size: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
}

.header_section_new_ui_event_page > button:hover {
  background: #f00;
  color: rgba(255, 255, 255, 1);
  border: 1px solid #f00;
}

@media (max-width: 600px) {
  .top_header_creator_profile {
    background-color: #000000;
    padding: 0px;
    height: 55px;
    padding-right: 15px;
    z-index: 999;
  }

  .logo_main_page {
    width: 150px;
    margin-left: 15px;
  }

  .top_header_creator_profile > button {
    width: 72px;
    /* height: 36px; */
    background: #000000;
    border: 0.5px solid #ffffff80;
    border-radius: 15px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
    margin-right: 12px;
  }

  .top_header_creator_profile > span {
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
  }

  .navbar_user_profile {
    width: 34px;
    height: 34px;
  }


  /* navbar 2  */

  .header_section_new_ui_event_page {
    padding: 20px 16px;
  }

  .header_section_new_ui_event_page > div > img {
    width: 91.741px;
    height: 25px;
  }

  .header_section_new_ui_event_page > button {
    font-size: 12px;
    padding: 8px 16px;
    border-radius: 38px;
    color: #fff;
    font-family: "Gilroy-Light", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .listing_options_navbar{
    right: 5%;
    padding: 10px 6px;
    width: 120px;
  }

  .listing_options_navbar > span{
    font-size: 12px;
    width: 78%;
  }


}
