.new_service_page_outside_wrapper {
  width: 100vw;
  background: #171e25;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  flex-direction: column;
}

.new_service_page_outside_wrapper > section {
  padding: 0 10%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

/* text ----------------------- */

.text01_new_service_page {
  color: #e2e8f0;
  font-family: "Inter";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text02_new_service_page {
  color: #94a3b8;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  align-items: center;
  gap: 4px;
}

.text03_new_service_page {
  color: #e2e8f0;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.text04_new_service_page {
  color: #94a3b8;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
}

.text04_new_service_page h1 {
  font-size: 25px !important;
  font-weight: bold !important;
  line-height: 28px !important;
  margin-bottom: 4px !important;
}

.text04_new_service_page h2 {
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  margin-bottom: 4px !important;
  line-height: 25px !important;
}

.text04_new_service_page h3 {
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
}

.text04_new_service_page h4 {
  font-size: 18px !important;
}

.text04_new_service_page * {
  font: inherit !important;
  background-color: inherit !important;
  color: inherit !important;
}

.text04_new_service_page ul {
  padding-left: 20px;
  font-size: 14px;
}

.text04_new_service_page ol {
  padding-left: 20px;
  font-size: 14px;
}

/* content section --------------------- */

.left_side_service_page_hoc {
  position: sticky;
  top: 0;
}

.right_side_service_page_hoc {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* width: 100%; */
  width: 53vw;
  position: relative;
}

.service_page_banner_wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 50%; /* Set the padding-bottom to maintain the 2:1 ratio (25% of the width) */
  position: relative;
}

.service_page_banner_wrapper > img {
  width: 100%; /* Make the image fill the container */
  height: 100%; /* Make the image fill the container */
  /* object-fit: cover;  */
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
}

.service_page_banner_wrapper > span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  padding: 20px;
  border-radius: 1000px;
  background: #0d1117;
  position: absolute;
  bottom: -40px;
  left: 40px;
  color: #cbd5e1;
}

.service_page_banner_wrapper > div {
  padding: 8px 16px;
  border-radius: 4px;
  background: rgba(18, 18, 18, 0.3);
  position: absolute;
  right: 13px;
  top: 20px;
  font-size: 16px;
  color: #F8F8F8;
  cursor: pointer;
}

.right_side_service_page_hoc > div {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
  margin-top: 40px;
}

.tags_new_service_page_wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.tags_new_service_page_wrapper > div {
  display: flex;
  padding: 8px 20px;
  align-items: center;
  gap: 8px;
  border-radius: 2px;
  background: #14181f;
}

.tags_new_service_page_wrapper > div svg {
  font-size: 24px;
  color: #94a3b8;
}

.tags_new_service_page_wrapper > div section {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 2px;

  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tags_new_service_page_wrapper > div section > span {
  color: #94a3b8;
}

.tags_new_service_page_wrapper > div section > p {
  color: #cbd5e1;
}

.desc_section_wrapper_service_page {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.extra_sections_wrapper_service_page {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* creatroe card css ---------------------------------------- */

.creator_card_service_page_wrapper {
  width: 25vw;
  /* max-width: 346px; */
  box-sizing: border-box;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
  border-radius: 8px;
  background: #14181f;
  box-shadow: 0px 0px 8px 0px rgba(18, 18, 18, 0.08);
}

.creator_card_service_page_wrapper > section img {
  width: 80px;
  height: 80px;
  border-radius: 80px;
  object-fit: cover;
}

.creator_card_service_page_wrapper > section > section span {
  color: #e2e8f0;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.creator_card_service_page_wrapper > p {
  color: #94a3b8;
  text-align: center;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.creator_card_service_page_wrapper > button {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #e2e8f0;
  text-align: center;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.creator_card_service_page_wrapper > section div {
  color: #cbd5e1;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: -12px;
}

.creator_card_service_page_wrapper > div > svg {
  cursor: pointer;
}

.creator_card_service_page_wrapper > div {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 24px;
  color: #94a3b8;
}

.creator_card_service_page_wrapper > section {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

/* floater ------------------ */

.floater_service_page_wrapper {
  padding: 12px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 450px;
  border-radius: 8px;
  background: #ff5c5c;
  position: sticky; /* Use sticky positioning */
  bottom: 16px; /* Adjust as needed */
  margin: 0 auto; /* Center the floater horizontally */
  cursor: pointer;
}

.floater_service_page_wrapper > section {
  display: flex;
  flex-direction: column;
  gap: 4.5px;
}

.floater_service_page_wrapper > section > p {
  color: #e2e8f0;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  align-items: center;
  gap: 4px;
}

.floater_service_page_wrapper > section > span {
  color: #fff;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.floater_service_page_wrapper > div {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;

  color: #ecfdf5;
  text-align: center;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media (max-width: 600px) {
  .new_service_page_outside_wrapper > section {
    flex-direction: column-reverse;
    padding: 0 4%;
  }

  .right_side_service_page_hoc {
    width: 100%;
  }

  .service_page_banner_wrapper > span {
    font-size: 24px;
    padding: 8px;
    left: 16px;
    bottom: -20px;
  }

  .service_page_banner_wrapper > div {
    right: 8px;
    top: 8px;
  }

  .right_side_service_page_hoc > div {
    margin-top: 20px;
    gap: 20px;
  }

  /* text types -------- */

  .text01_new_service_page {
    font-size: 18px;
  }

  .text02_new_service_page {
    font-size: 12px;
  }

  .text03_new_service_page {
    font-size: 16px;
  }

  .text04_new_service_page {
    font-size: 14px;
  }

  .text04_new_service_page h1 {
    font-size: 20px !important;
    line-height: 28px !important;
  }

  .text04_new_service_page h2 {
    font-size: 18px !important;
    line-height: 25px !important;
  }

  .text04_new_service_page h3 {
    font-size: 16px !important;
  }

  .text04_new_service_page h4 {
    font-size: 14px !important;
  }

  .tags_new_service_page_wrapper {
    gap: 8px;
    flex-wrap: wrap;
  }

  .tags_new_service_page_wrapper > div {
    padding: 4px 8px;
  }

  .tags_new_service_page_wrapper > div svg {
    font-size: 20px;
  }

  .tags_new_service_page_wrapper > div section {
    font-size: 12px;
  }

  /* floater ---------------- */

  .floater_service_page_wrapper {
    width: 92vw;
    box-sizing: border-box;
    padding: 12px 16px;
    align-items: flex-end;
    /* position: fixed; */
    /* left: 4vw; */
    z-index: 99999;
  }

  .floater_service_page_wrapper > section > p {
    font-size: 10px;
  }

  .floater_service_page_wrapper > div {
    font-size: 16px;
    gap: 8px;
  }

  /* creator card ------------------------ */

  .creator_card_service_page_wrapper {
    width: 100%;
    padding: 20px 16px;
    margin-top: 16px;
  }

  .creator_card_service_page_wrapper > section {
    flex-direction: row;
    align-items: flex-start;
  }

  .creator_card_service_page_wrapper > section img {
    width: 40px;
    height: 40px;
    border-radius: 40px;
  }

  .creator_card_service_page_wrapper > section > section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    text-align: left;
  }

  .creator_card_service_page_wrapper > section > section span {
    font-size: 16px;
  }

  .creator_card_service_page_wrapper > section > section p {
    color: #94a3b8;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .creator_card_service_page_wrapper > section > div {
    font-size: 12px;
    gap: 8px;
    flex-wrap: nowrap;
    margin-top: unset;
  }

  .creator_card_service_page_wrapper > button {
    font-size: 12px;
  }
}
