@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@200;300;400;600;700&display=swap");



.container_card > input {
  display: none;
}


.cards_all_inside_homepage {
  position: relative;
  height: 500px;
  width: 430px;
  perspective: 1000px;
  transform-style: preserve-3d;
}

.cards_all_inside_homepage label {
  position: absolute;
  width: 630px;
  left: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
  transition: transform 0.55s ease;
}

#s1:checked ~ .cards_all_inside_homepage #slide1,
#s2:checked ~ .cards_all_inside_homepage #slide2,
#s3:checked ~ .cards_all_inside_homepage #slide3,
#s4:checked ~ .cards_all_inside_homepage #slide4 {
  
  transform: translate3d(0, 0, 0);

}

#s1:checked ~ .cards_all_inside_homepage #slide2,
#s2:checked ~ .cards_all_inside_homepage #slide3,
#s3:checked ~ .cards_all_inside_homepage #slide4,
#s4:checked ~ .cards_all_inside_homepage #slide1 {
 transform: translate3d(28%, 0, -80px);
 
}

#s1:checked ~ .cards_all_inside_homepage #slide3,
#s2:checked ~ .cards_all_inside_homepage #slide4,
#s3:checked ~ .cards_all_inside_homepage #slide1,
#s4:checked ~ .cards_all_inside_homepage #slide2 {
  transform: translate3d(56%, 0, -160px);

}

#s1:checked ~ .cards_all_inside_homepage #slide4,
#s2:checked ~ .cards_all_inside_homepage #slide1,
#s3:checked ~ .cards_all_inside_homepage #slide2,
#s4:checked ~ .cards_all_inside_homepage #slide3 {
  
  transform: translate3d(84%, 0, -240px);
}

.outer_home_card_body {
  margin-top: 74px;
  
}

.outer_home_card_body_01 {
  display: flex;
  width: 641px;
  height: 378px;
  padding: 40px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: linear-gradient(133deg, #700 0%, #121212 75%);
}

.outer_home_card_body_02 {
  text-align: left;
  color: #fff;
  font-family: "Gilroy-Bold",sans-serif;
  font-size: 38px;
  font-style: normal;
  font-weight: bold;
}

.outer_home_card_body_03 {
  text-align: left;
  color: #717171;
  font-family: "Gilroy-Regular",sans-serif;
  font-size: 24px;
  font-style: normal;
}


/* {{ color: 'white', fontSize: '80px' }} */